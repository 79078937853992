import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS, Z_INDEX } from "../../utils/style";
import contentBg1 from "../../assets/images/char-page-pic-01.png";
import contentBg2 from "../../assets/images/char-page-pic-02.png";
import headerBg from "../../assets/images/char-title-bg.png";

const TopLeftLogo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 240px;
  cursor: pointer;
  z-index: ${Z_INDEX.fixed};

  img {
    width: 100%;
  }

  &:hover {
    img {
      width: 103%;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 200px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 40%;
    max-width: 140px;
  }
`;

const CharacterPageContainer = styled.div`
  ${containerStyle}
  background: url("${process.env.GATSBY_ROOT_PATH}/char-page-bg.jpg") top center/cover repeat;
  padding-bottom: 100px;
  overflow-x: hidden;
  animation: fadeIn 1s;
`;

const CharacterContent = styled.section`
  width: 1000px;
  overflow-x: visible;
  padding: 200px 0 150px;
  margin: 0 auto;
  position: relative;
  background: url(${contentBg1}) top 220px left/30% no-repeat,
    url(${contentBg2}) top 160px right/75% no-repeat;
  z-index: 5;

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    width: 90%;
    background: url(${contentBg1}) top 220px left/30% no-repeat,
      url(${contentBg2}) top 180px right/70% no-repeat;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    padding: 140px 0 70px;
    width: 100%;
    background: url(${contentBg2}) right top 45vh / 80% no-repeat;
  }
`;

const CharacterContentInner = styled.section`
  width: 100%;
  height: 100%;
`;

const CharacterHeader = styled.header`
  height: 74px;
  background: url(${headerBg}) top left/contain no-repeat;
  position: relative;
  z-index: ${Z_INDEX.title};

  > div {
    position: absolute;
    top: -10px;
    left: 0;
    font-style: italic;
    font-weight: 700;
  }

  h1 {
    display: inline-block;
    font-size: 60px;

    &.character__header--long {
      font-size: 50px;
    }
  }

  h3 {
    display: inline-block;
    font-size: 35px;
    margin-left: 10px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 90%;
    height: 70px;
    margin-left: 5%;

    h1 {
      font-size: 55px;

      &.character__header--long {
        font-size: 48px;
      }
    }

    h3 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    max-width: 360px;
    margin-left: 3%;

    > div {
      top: -5px;
      left: 5px;
    }

    h1 {
      font-size: 38px;

      &.character__header--long {
        font-size: 26px;
      }
    }

    h3 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    > div {
      top: -12px;
      left: 5px;
    }
  }
`;

const CharacterIntro = styled.div`
  width: 480px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
  z-index: 5;
  position: relative;

  > div {
    width: 100%;
    margin: 30px 0;
  }

  > ul {
    font-style: italic;
    list-style: none;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 100%;
    padding: 0 8%;
    margin-top: max(65vh, 400px);
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    font-size: 15px;
  }
`;

const CharacterVideoPreview = styled.img`
  width: 320px;
  background: url(${props => props.img}) center/contain no-repeat;
  cursor: pointer;
  margin-top: 30px;
  z-index: ${Z_INDEX.clickable};
  position: relative;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 270px;
    position: absolute;
    top: max(48vh, 300px);
    left: 5%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 170px;
    top: max(50vh, 300px);
    left: 3%;
  }

  @media screen and (max-width: 480px) {
    width: 130px;
    top: 55vh;
  }
`;

const CharacterAnimation = styled.div`
  position: relative;

  .character-animation {
    max-width: 900px;
    position: absolute;
    top: -200px;
    right: -240px;
    z-index: 2;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    .character-animation {
      height: 80vh;
      min-height: 500px;
      top: 0;
      right: -80px;

      @media screen and (max-height: 450px) and (orientation: landscape) {
        height: 150vh;
        right: 0;
      }
    }
  }
`;

const CharacterSwitchBtn = styled.div`
  width: 120px;
  height: 110px;
  background: url(${props => props.img}) center/contain no-repeat;
  cursor: pointer;
  position: absolute;
  top: 440px;
  left: 300px;
  z-index: ${Z_INDEX.clickable};
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.04);
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 100px;
    height: 90px;
    top: max(20vh, 60px);
    left: 22%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 75px;
    height: 65px;
    top: max(20vh, 100px);
    left: 12%;
  }

  @media screen and (max-width: 480px) {
    top: 20vh;
  }
`;

const CharacterSlogan = styled.img`
  width: 660px;
  position: absolute;
  top: 550px;
  right: 0;
  z-index: 5;

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    width: 60%;
    max-width: 600px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 70%;
    max-width: 500px;
    top: max(58vh, 380px);
    right: 10%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 80%;
    max-width: 400px;
    top: max(calc(40px + 50vh), 380px);
    right: 3%;
  }

  @media screen and (max-width: 480px) {
    top: calc(40px + 50vh);
  }
`;

export {
  TopLeftLogo,
  CharacterHeader,
  CharacterPageContainer,
  CharacterContent,
  CharacterContentInner,
  CharacterIntro,
  CharacterAnimation,
  CharacterSlogan,
  CharacterSwitchBtn,
  CharacterVideoPreview,
};
